import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  textAlign: "center",
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function BasicModal({open, setOpen, handleClose}) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <Typography color="black" variant="h5">
          Terms and conditions
        </Typography>
          <Typography color="black" id="modal-modal-description">
          Use of the application is intended solely for private, non-commercial use by you, the user. Picit grants the user a personal, limited, non-exclusive, revocable, non-transferable, non-transferable license to use the company's application, all subject to the provisions of this agreement. The application is intended for the personal and exclusive use of the user, and any commercial use of it and/or use for profit-making purposes is prohibited by this agreement. 
          <br/>
          1. As part of using the company's application, the user is not authorized to <br/>
          2. Transfer, distribute, share, mortgage, assign, grant a sublicense, sell, lease or rent the application or his rights therein directly or indirectly, in a commercial or non-commercial manner; <br/>
          3. make any copying, duplication, modification, conversion, compilation, disassembly, re-engineering or any other change in the application and/or its parts; <br/>
          4. integrate the application with a third-party product and/or third-party software and/or any derivative work without the prior written approval of the company; <br/>
          5. Transmit or create, in connection with the application, any virus, "worm," "Trojan horse", spyware, or any other code, file, or program that may or is intended to cause damage or take over the operation of any hardware, software or communication equipment, or any other computer code or component or file that is, or may be, harmful, disruptive or intrusive; <br/>
          6. use the application for the purpose of developing a competing application and/or software; <br/>
          7. Create a database by systematically downloading content from the application; <br/>
          8. Interfere with or disrupt the activity of the service and/or the website, or the servers or networks that host the application or enable its availability;<br/>
            
           </Typography>
          <Button onClick={handleClose}>Accept</Button>
        </Box>
      </Modal>
    </div>
  );
}
